// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-entry-js": () => import("./../../../src/templates/blog_entry.js" /* webpackChunkName: "component---src-templates-blog-entry-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog_list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-static-js": () => import("./../../../src/templates/static.js" /* webpackChunkName: "component---src-templates-static-js" */),
  "component---src-templates-thanks-js": () => import("./../../../src/templates/thanks.js" /* webpackChunkName: "component---src-templates-thanks-js" */)
}

